const locations = [
  {
    location: 'Auckland', districts: [
      'Auckland City',
      'Franklin',
      'Manukau City',
      'North Shore City',
      'Papakura',
      'Rodney',
      'Waiheke Island',
      'Waitakere City'
    ]
  },
  {
    location: 'Bay Of Plenty', districts: [
      'Rotorua',
      'Tauranga',
      'Western Bay Of Plenty',
      'Whakatane'
    ]
  },
  {
    location: 'Canterbury', districts: [
      'Ashburton',
      'Banks Peninsula',
      'Christchurch City',
      'Hurunui',
      'Mackenzie',
      'Selwyn',
      'Timaru',
      'Waimakariri',
      'Waimate'
    ]
  },
  {
    location: 'Hawkes Bay', districts: [
      'Central Hawkes Bay',
      'Hastings',
      'Napier'
    ]
  },
  {
    location: 'Manawatu / Whanganui', districts: [
      'Horowhenua',
      'Manawatu',
      'Palmerston North',
      'Rangitikei',
      'Ruapehu',
      'Whanganui'
    ]
  },
  {
    location: 'Marlborough', districts: [
      'Blenheim',
      'Kaikoura',
      'Marlborough'
    ]
  },
  {
    location: 'Nelson / Tasman', districts: [
      'Nelson',
      'Tasman'
    ]
  },
  {
    location: 'Northland', districts: [
      'Far North',
      'Kaipara',
      'Whangarei'
    ]
  },
  {
    location: 'Otago', districts: [
      'Central Otago',
      'Clutha',
      'Dunedin',
      'Queenstown-Lakes',
      'Waitaki',
      'Wanaka'
    ]
  },
  {
    location: 'Southland', districts: [
      'Gore',
      'Invercargill',
      'Southland'
    ]
  },
  {
    location: 'Taranaki', districts: [
      'New Plymouth',
      'South Taranaki',
      'Stratford'
    ]
  },
  {
    location: 'Waikato', districts: [
      'Hamilton',
      'Hauraki',
      'Matamata-Piako',
      'South Waikato',
      'Taupo',
      'Thames-Coromandel',
      'Waikato',
      'Waipa'
    ]
  },
  {
    location: 'Wellington', districts: [
      'Carterton',
      'Kapiti Coast',
      'Lower Hutt',
      'Masterton',
      'Porirua',
      'South Wairarapa',
      'Upper Hutt',
      'Wellington'
    ]
  },
  {
    location: 'West Coast', districts: [
      'Grey'
    ]
  }
];


export {
  locations
}
