<template>
  <b-container>
    <b-row>
      <b-col sm="12" md="6" offset-md="3">
        <b-card
          class="mt-5"
          style="z-index: 2"
        >
          <b-card-title class="text-center">
            <img src="@/assets/allhouse.svg" style="height: 50px;">
            <h5>SIGN UP</h5>
          </b-card-title>
          <b-form @submit="submit">
            <b-form-group
              description="Enter your first/given name"
            >
              <b-form-input
                v-model="firstName"
                required
                placeholder="First Name"
              />
            </b-form-group>
            <b-form-group
              description="Enter your last/family name"
            >
              <b-form-input
                v-model="lastName"
                required
                placeholder="Last Name"
              />
            </b-form-group>
            <b-form-group
    
              description="Enter your date of birth"
            >
              <b-form-input
                required
                v-model="dob"
                placeholder="Date of Birth"
                type="text"
                onfocus="(this.type='date')" onblur="(this.type='text')"
              />
            </b-form-group>
            <b-form-group
            
            description="Enter your email address"
          >
            <b-form-input
              required
              v-model="email"
              placeholder="Email"
              type="email"
            />
          </b-form-group>
          <b-form-group
            description="Enter your password"
          >
            <b-form-input
              required
              type="password"
              placeholder="Password"
              v-model="password"
            />
          </b-form-group>
            <b-form-group
              description="Please confirm your password"
            >
              <b-form-input
                required
                type="password"
                placeholder="Confirm Password"
                v-model="passwordRepeat"
              />
            </b-form-group>
          <b-form-group class="text-center">
            <img src="@/assets/loading_gif.gif" v-if="loading">
            <b-button
              block
              variant="dark"
              type="submit"
              v-if="!loading"
            >
              CREATE ACCOUNT
            </b-button>
          </b-form-group>
            <b-row>
              <b-col>
                <b-button variant="light" href="/sign-in">
                  Already have an account?
                </b-button>
              </b-col>
              <b-col class="text-right">
                <b-button variant="light" href="/forgot-password">
                  Forgot Password?
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <div class="background">
      <img src="@/assets/line-desktop.svg"/>
    </div>
  </b-container>
</template>

<script>
  import {mapGetters} from "vuex";
  
  export default {
    name: "sign-up",
    data: () => ({
      firstName: '',
      lastName: '',
      dob: '',
      email: '',
      password: '',
      passwordRepeat: ''
    }),
    computed: {
      ...mapGetters('session', ['loading'])
    },
    methods: {
      async submit(e) {
        e.preventDefault()
        await this.$store.dispatch('session/SIGN_UP', {
          firstName: this.firstName,
          lastName: this.lastName,
          dob: this.dob,
          email: this.email,
          password: this.password,
          passwordRepeat: this.passwordRepeat
        });
      }
    }
  }
</script>

<style scoped>
  .background {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255,255,255,.5);
  }
  
  .background img {
    width: 100%;
    height: 200%;
    background-size: cover;
    transform: rotate(90deg);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  
  button[type="submit"] {
    background-color: #000320;
  }
</style>
