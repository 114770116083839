import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    showSearchResults: false,
    selectedListing: null,
    searchResults: [],
    latestListings: [],
    coolestPads: [],
    trendingProperties:[],
    viewingTimes: null
  },
  mutations,
  getters,
  actions
}
