<template>
  <div id="app" style="--dark:#000 !important;">
    <top-nav/>
    <search-filter/>
    <router-view/>
    <navigation-sidebar/>
    <onboarding
      v-if="user && !user.hasCompletedOnboarding"/>
    <loader />
  </div>
</template>

<script>
  import TopNav from "./components/top-nav";
  import NavigationSidebar from "./components/navigation-sidebar";
  import SearchFilter  from './components/search-filter';
  import {mapGetters} from "vuex";
  import Onboarding from "./components/onboarding";
  import Loader from "./components/loader";
  
  export default {
    name: 'App',
    components: {Loader, Onboarding, NavigationSidebar, TopNav, SearchFilter},
    computed: {
      ...mapGetters('session', ['user'])
    },
    mounted() {
      if (this.$store.getters.token) this.$store.dispatch('session/GET_USER');
    }
  }
</script>

<style lang="scss">
  @import 'assets/custom-theme.scss';
  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';
  
  #app {
    background-color: #fafafa;
    height: 100%;
    display: block;
  }
</style>
