<template>
  <b-modal
    scrollable
    id="listing-modal"
    :size="stage === 'description'? 'xl' : 'md'"
    header-bg-variant="dark"
    header-text-variant="light"
    @close="onClose"
  >
    <template #modal-title >
      <b-row>
        <b-col>
          <img
            src="@/assets/allhouse_white_flag_transparent.png"
            style="height: 30px"
          />
          {{selectedListing.location.address.suburb}}
        </b-col>
      </b-row>
    </template>
    <b-row v-if="loading">
      <b-col class="text-center py-5">
        <h5>Loading...</h5>
      </b-col>
    </b-row>
    <description-page
      v-if="stage === 'description' && selectedListing && !loading"
      :selected-listing="selectedListing"
    />
    <viewings-page
      v-if="stage === 'viewings' && !loading"
      :selected-listing="selectedListing"
      :viewing-times="viewingTimes"
      :loading="loading"
      ref="viewingPage"
      @new-action="setViewingAction"
      />
    <success-page
      v-if="stage === 'success' && !loading"/>
    <template #modal-footer>
      <b-row class="w-100" v-if="stage === 'description'">
        <b-col>
          <h4 class="text-left">${{selectedListing.description.rent}}p/w</h4>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            variant="dark"
            @click="getViewingTimes"
          >VIEW OR APPLY</b-button>
        </b-col>
      </b-row>
      <b-row class="w-100" v-if="stage === 'viewings' && !loading">
        <b-col>
          <b-button
            variant="outline-secondary"
            @click="stage = 'description'"
          >
            Back
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-if="viewingAction !== 'apply'"
            class="float-right"
            variant="dark"
            @click="bookViewing"
          >{{user ? 'BOOK' : 'SIGN IN'}}</b-button>
        </b-col>
      </b-row>
      <b-row class="w-100" v-if="stage === 'success' && !loading">
        <b-col>
          <b-button
            variant="outline-secondary"
            @click="stage = 'viewings'"
          >
            Back
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            variant="dark"
            @click="finish"
          >CLOSE</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import {mapGetters} from "vuex";
  import moment from 'moment';
  import DescriptionPage from "./description-page";
  import ViewingsPage from "./viewings-page";
  import SuccessPage from "./success-page";
  
  export default {
    name: "listing-details-modal",
    components: {SuccessPage, ViewingsPage, DescriptionPage},
    data: () => ({
      isOpen: false,
      stage: 'description',
      viewingTimes: null,
      loading: false,
      viewingAction: 'viewing'
    }),
    computed: {
      ...mapGetters('listing', ['selectedListing']),
      ...mapGetters('session', ['user'])
    },
    methods: {
      moment,
      onClose() {
        this.$router.push({
          query: {
          
          }
        })
      },
      setViewingAction(action) {
        this.viewingAction = action;
      },
      async bookViewing() {
        if (!this.user) return this.$router.push(`/sign-in?returnTo=${encodeURIComponent(`/?id=${this.selectedListing._id}&stage=viewings`)}`);
        this.loading = true;
        let result = await this.$store.dispatch('listing/BOOK_VIEWING', {
          id: this.selectedListing._id,
          listingId: this.selectedListing.trademeId,
          viewingInfo: this.$refs['viewingPage'].getViewingInfo()
        });
        
        if (result.status === 200) this.stage = 'success';
        this.loading = false;
        
      },
      async getViewingTimes() {
        this.stage = 'viewings';
        if (this.selectedListing.trademeId) {
          this.loading = true;
          this.viewingTimes = await this.$store.dispatch('listing/GET_VIEWING_TIMES', {listingId: this.selectedListing._id})
          this.loading = false;
        }
        
      },
      finish() {
        this.$bvModal.hide('listing-modal');
        this.stage = 'description';
      }
    }
  }
</script>

<style scoped>
  header#listing-modal* {
    background: #000 !important;
  }
  :root {
    --dark: #000;
  }
</style>
