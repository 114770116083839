<template>
  <b-collapse id="search-collapse">
    <b-card>
      <b-container>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group label="Location">
              <b-row>
                <b-col>
                  <b-dropdown
                    :text="location.location"
                    class="w-100 not-rounded"
                    variant="outline-dark"
                    toggle-class="not-rounded"
                    no-caret
                  >
                    <b-dropdown-header>Regions</b-dropdown-header>
                    <b-dropdown-item
                      v-for="(loc, index) in locations"
                      :key="`location-${index}`"
                      @click="setLocation(loc)"
                    >
                      {{loc.location}}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                <b-col>
                  <b-dropdown
                    :text="district"
                    class="not-rounded w-100"
                    variant="outline-dark"
                    toggle-class="not-rounded"
                    no-caret
                  >
                    <b-dropdown-header>Districts</b-dropdown-header>
                    <b-dropdown-item
                      v-for="(dis, index) in districts"
                      :key="`district-${index}`"
                      @click="district = dis"
                    >
                      {{dis}}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Price Range"
            >
              <b-row>
                <b-col>
                  <b-form-spinbutton
                    class="not-rounded w-45"
                    v-model="priceRange[0]"
                    step="50"
                    :min="0"
                    :max="priceRange[1] - 50"
                    :locale="'en'"
                    :formatter-fn="moneyFormatter"
    
                  />
                </b-col>
  
                <b-col>
                  <b-form-spinbutton
                    class="not-rounded w-45"
                    v-model="priceRange[1]"
                    step="50"
                    :min="priceRange[0] + 50"
                    :max="2000"
                    :locale="'en'"
                    :formatter-fn="moneyFormatter"
    
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Bedrooms"
            >
              <b-form-spinbutton
                class="not-rounded"
                v-model="bedrooms"
                step="1"
                :min="0"
                :max="10"
                :formatter-fn="plusFormatter"
  
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Bathrooms"
            >
              <b-form-spinbutton
                class="not-rounded"
                v-model="bathrooms"
                step="1"
                :min="0"
                :max="10"
                :formatter-fn="plusFormatter"
      
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              block
              squared
              variant="dark"
              @click="searchListings"
            >
              SEARCH
            </b-button>
            <b-button
              block
              squared
              variant="light"
              v-b-toggle.search-collapse
            >
              CLOSE
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-collapse>
</template>

<script>
  import {locations} from "../utils/constants";

  let defaultLocation = {location: 'All New Zealand', districts:[]};
  let defaultDistrict = 'All Districts';
  
  export default {
    name: "search-filter",
    data: () => ({
      bedrooms: 0,
      bathrooms: 0,
      location: defaultLocation,
      district: defaultDistrict,
      priceRange: [100, 900],
      priceFormatter: v => `$${v}`,
    }),
    methods: {
      moneyFormatter(value) {
        return `$${value}`
      },
      plusFormatter(value) {
        return value < 1 ? 'Any' : `${value}+`
      },
      setLocation(l) {
        this.location = l
      },
      async searchListings() {
        let filter = {
          type: "custom-search",
          rentRange:this.priceRange,
          bedrooms: this.bedrooms,
          bathrooms: this.bathrooms
        }
        
        if (this.location.location !== defaultLocation.location) filter.stateOrRegion = this.location.location;
        if (this.district !== defaultDistrict) filter.district = this.district;
        
        let listings = await this.$store.dispatch('listing/GET_LISTINGS', {
          options: {"sort":"-description.rent","limit": 100},
          filter
        });
        
        this.$store.commit('listing/setSearchResults', listings);
        this.$store.commit('listing/setShowSearchResults', true);
      }
    },
    computed: {
      locations() {
        return [defaultLocation, ...locations];
      },
      districts() {
        return ['All Districts', ...this.location.districts];
      }
    }
  }
</script>

<style scoped>
  .not-rounded {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
  }

  .not-rounded > button {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
  }
</style>
