<template>
  <b-row style="min-height: 330px">
    <b-col>
      
      <b-form-group
        label="You wish to..."
      >
        <b-form-radio-group
          v-model="action"
          :options="options"
        />
      </b-form-group>
      <b-row v-if="action === 'viewing'">
        <b-col class="px-5 py-4" cols="12">
          <b-form-group
            label="What’s your move in date?"
          >
            <b-form-datepicker
              id="moving-datepicker"
              v-model="movingDate"
              class="mb-2"/>
          </b-form-group>
          <b-form-group
            label="Preferred Viewing Time"
          >
            <b-row>
              <b-col v-if="!loading">
                <b-form-radio
                  v-for="(viewingTime, key) of viewingTimes"
                  :key="viewingTime"
                  :value="{date: key, viewingId: Object.keys(viewingTime)[0], time: viewingTime[Object.keys(viewingTime)[0]]}"
                  v-model="selectedViewingTime"
                  name="viewing-time-radio"
                >
                  <h5>{{viewingTime[Object.keys(viewingTime)[0]]}} {{key}}</h5>
                </b-form-radio>
                <b-form-radio
                  name="viewing-time-radio"
                  :value="defaultViewing"
                  v-model="selectedViewingTime"
                >
                  <h5>Request a viewing time</h5>
                </b-form-radio>
              </b-col>
              <b-col v-else class="text-center">
                <img src="@/assets/loading_gif.gif">
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="text-center">
          <b-button
            v-if="selectedListing.integrations.tps.applicationForm"
            block
            variant="dark"
            :href="selectedListing.integrations.tps.applicationForm"
          >
            GO TO APPLICATION FORM
          </b-button>
          <h5 v-else>No application form available for this property</h5>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  import moment from 'moment';
  const defaultViewing = {
    date: "request",
    viewingId: null,
    time: null,
  }
  export default {
    name: "viewings-page",
    props: ['viewingTimes', 'loading', 'selectedListing'],
    data: () => ({
      action: 'viewing',
      movingDate: null,
      movingHubSubmit: false,
      selectedViewingTime: JSON.parse(JSON.stringify(defaultViewing)),
      defaultViewing,
      options: [{
        text: 'Book a Viewing',
        value: 'viewing'
      }, {
        text: 'Apply to this property',
        value: 'apply'
      }]
    }),
    methods: {
      getViewingInfo() {
        const {movingDate, movingHubSubmit, selectedViewingTime} = this;
        return {
          selectedDate: moment(movingDate, 'YYYY-MM-DD').toDate(),
          movingHubSubmit,
          viewingTime: JSON.stringify(selectedViewingTime)
        }
      }
    },
    watch: {
      action(newVal) {
        if (newVal) this.$emit('new-action', newVal);
      }
    }
  }
</script>

<style scoped>
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  
  .separator:not(:empty)::before {
    margin-right: 1.25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: 1.25em;
  }
</style>
