<template>
  <b-container class="mt-3">
    <b-row v-if="showSearchResults">
      <b-col>
        <h4 class="pl-2 m-0">
          <img src="@/assets/icons/allhouse_Illustrations_Search.png" style="width:44px"/>
          Your Search</h4>
        <div class="properties-container">
          <listing-card
            v-for="listing of searchResults"
            :key="listing._id"
            :listing="listing"
          />
        </div>
        <b-row>
          <b-col>
            <b-alert :show="searchResults.length < 1" variant="light">
              <h3 class="text-center">Oops!
                <img src="@/assets/icons/monster.svg" width="50"/>
              </h3>
              <h4 class="text-center">
                We haven't listed any properties here just yet
                <br/>
                PS... New properties are listed daily, so come back tomorrow
              </h4>
            </b-alert>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="pl-2 m-0">
          <img src="@/assets/icons/icon - Rocket ship.png" style="width:44px"/>
          Latest Listings
        </h4>
        <div class="properties-container">
          <listing-card
            v-for="listing of latestListings"
            :key="listing._id"
            :listing="listing"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="pl-2 m-0">
          <img src="@/assets/icons/icon - emoji smile.png" style="width:44px"/>
          Coolest Pads
        </h4>
        <div class="properties-container">
          <listing-card
            v-for="listing of coolestPads"
            :key="listing._id"
            :listing="listing"
          />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="trendingProperties && trendingProperties.length > 0">
      <b-col>
        <h4 class="pl-2 m-0">
          <img src="@/assets/icons/Icon - Fire.png" style="width:44px"/>
          Trending Properties
        </h4>
        <div class="properties-container">
          <listing-card
            v-for="listing of trendingProperties"
            :key="listing._id"
            :listing="listing"
          />
        </div>
      </b-col>
    </b-row>
    <listing-details-modal/>
  </b-container>
</template>

<script>

  import {mapGetters} from "vuex";
  import ListingCard from "../components/listing-card";
  import ListingDetailsModal from "../components/listing-details-modal/index";

  export default {
    name: "listings",
    components: {ListingDetailsModal, ListingCard},
    computed: {
      ...mapGetters('listing', [
        'listings',
        'latestListings',
        'coolestPads',
        'trendingProperties',
        'showSearchResults',
        'searchResults',
        'selectedListing'
      ])
    },
    async mounted() {
      this.$store.dispatch('listing/GET_LASTEST_LISTINGS', {})
      this.$store.dispatch('listing/GET_COOLEST_PADS', {})
      this.$store.dispatch('listing/GET_TRENDING_PROPERTIES', {});
      const {query} = this.$router.currentRoute;
      if (query.id) {
        console.log('getting listing')
        let listing = await this.$store.dispatch('listing/GET_LISTING', {id: query.id});
        console.log('setting listing')
        this.$store.commit('listing/setSelectedListing', listing);
        setTimeout(() => {
          console.log('opening modal')
          this.$bvModal.show('listing-modal')
        }, 1000)
      }
    }
  }
</script>

<style scoped>
  .properties-container {
    width: 100%;
    overflow: scroll;
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
  
  }

  .properties-container::-webkit-scrollbar {
    display: none;
  }
  
  .property-card {
    padding: 0px;
    max-width: 350px;
    min-width: 295px;
    display: block;
    margin: auto;
    text-align: center;
    margin: 10px;
    border: none;
  }
</style>
