<template>
  <b-row>
    <b-col>
      <h4 class="text-center">Success, You are almost there!</h4>
      <b-row>
        <b-col cols="2">
          <img src="@/assets/icons/mail.svg" width="50"/>
        </b-col>
        <b-col cols="10 px-0 pt-1">
          <p>
            You will recieve be notified by text and email confirming your viewing
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <img src="@/assets/icons/snail.svg" width="50"/>
        </b-col>
        <b-col cols="10 px-0 pt-1">
          <p>
            If you are running late or are unable to turn up to the viewing, communicate clearly!
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <img src="@/assets/icons/creativity.svg" width="50"/>
        </b-col>
        <b-col cols="10 px-0 pt-1">
          <p>
            Join the Allhouse Crew to get freebies, merch & invites to exclusive events
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "success-page"
  }
</script>

<style scoped>

</style>
