<template>
  <div id="onboarding">
    <div class="background">
      <img src="@/assets/hero.png"/>
    </div>
    <b-container>
      
      <b-card class="mt-3">
        <b-card-title class="text-center">Please tell us a little more about you</b-card-title>
        <b-card-body>
          <b-form @submit="onSubmit">
          <div v-if="!success">
            
            <b-row>
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Phone Number"
                >
                  <b-input-group prepend="+64">
                    <b-form-input
                      v-model="phone"
                      required
                      placeholder="e.g: 0211111111"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Occupation"
                >
                  <b-form-input
                    v-model="occupation"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Would you like to declare WINZ?"
                >
                  <b-form-radio-group
                    :options="yesNoOptions"
                    v-model="tenantProfile.isWINZ"
                  >
  
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Will you bring pets?"
                >
                  <b-form-radio-group
                    :options="yesNoOptions"
                    v-model="tenantProfile.hasPets"
                  >
  
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Are you currently renting?">
                  <b-form-radio-group
                    :options="yesNoOptions"
                    v-model="tenantProfile.isRenting"
                  >
        
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="tenantProfile.isRenting"
            >
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Current Address"
                >
                  <vue-google-autocomplete
                    id="map"
                    classname="form-control"
                    placeholder="Enter your address"
                    country="nz"
                    required
                    v-model="tenantProfile.currentAddress.formattedAddress"
                    :types="'geocode'"
                    v-on:placechanged="setAddress">
                  </vue-google-autocomplete>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                lg="6">
                <b-form-group
                  label="Current Address start date"
                >
                  <b-form-datepicker
                    v-model="tenantProfile.currentAddressStartDate"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
            >
              <b-form-checkbox
                v-model="agreedToTerms"
              >I agree to terms and conditions</b-form-checkbox>
            </b-form-group>
          </div>
  
          <div v-else>
            <h2>Too easy, you're signed up</h2>
            <p>Start searching for a new pad, or Register to Join the
              Crew!</p>
          </div>
  
          <b-button
            pill
            variant="dark"
            type="submit"
            :disabled="!agreedToTerms"
          >
            {{success ? 'Find A Place To Live' : `Let's Go`}}
            <i class="fas fa-arrow-right"></i>
          </b-button>
          </b-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import VueGoogleAutocomplete from 'vue-google-autocomplete';
  import {mapGetters} from "vuex";
  
  export default {
    name: "onboarding",
    components: {
      VueGoogleAutocomplete
    },
    computed: {
      ...mapGetters('session', ['user']),
    },
    data: () => ({
      phone: '',
      tenantProfile: {
        hasPets: false,
        isWINZ: false,
        currentAddress: {
          formattedAddress: '',
          address: {}
        },
        isRenting: false,
        currentAddressStartDate: null
      },
      occupation: '',
      success: false,
      dob: (new Date(1990,0,1)),
      agreedToTerms: false,
      yesNoOptions: [
        {text: 'Yes', value: true},
        {text: 'No', value: false},
      ],
      name: {
        given: '',
        family: ''
      }
    }),
    methods: {
      setAddress(geocodedAddress, place) {
        this.tenantProfile.currentAddress.address = place;
        this.tenantProfile.currentAddress.formattedAddress = place.formatted_address
      },
      async onSubmit(event) {
        event.preventDefault();
        if (!this.success) {
          await this.$store.dispatch('session/UPDATE_USER_INFO', {
            skipReload: true,
            id: this.user._id,
            userInfo: {
              tenantProfile: this.tenantProfile,
              'tenantProfile.currentAddress.formattedAddress': this.tenantProfile.currentAddress.address.formatted_address,
              'profile.dob': this.dob,
              agreedToTerms: this.agreedToTerms,
              'profile.occupation': this.occupation,
              'profile.phone': this.phone,
              name: this.name,
              hasCompletedOnboarding: true
            }
          })
          this.success = true;
        } else {
          this.$store.dispatch('session/GET_USER',{});
        }
      }
    }
  }
</script>

<style scoped>
  #onboarding {
    position: fixed;
    top: 80px;
    left: 0px;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 599;
    background-color: #fafafa;
  }

  .background {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255,255,255,.5);
  }

  .background img {
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }
</style>
