import axios from 'axios';
import Vue from 'vue';
import {Auth} from "aws-amplify"
import '../../../utils/auth';

export default {
  SIGN_IN: async ({commit, rootState, dispatch}, {email, password, returnTo = '/'}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data: {token}} = await axios({
        url: `${rootState.apiAddress}/login`,
        method: 'post',
        data: {
          username: email, password
        }
      })
      commit('setToken', token, {root: true});

      await dispatch('GET_USER');
      window.location.href = returnTo;
    } catch (err) {

      if (err && err.response.status !== 403) {
        commit('setLoading', false, {root: true});
        const message = err.response && err.response.data
          ? err.response.data
          : rootState.defaultError

        Vue.toasted.global.showServerError({
          message
        }).goAway(4000)
      } else {
        const cognitoUser = await Auth.signIn(email, password);
        const {data: {token,}} = await axios({
          url: `${rootState.apiAddress}/login`,
          method: 'post',
          data: {
            username: email, password, cognitoId: cognitoUser.username
          }
        })
        commit('setToken', token, {root: true});
        await dispatch('GET_USER');
        commit('setLoading', false, {root: true});
        window.location.href = returnTo;
      }
    }

    commit('setLoading', false, {root: true});
  },
  SIGN_UP: async ({commit, rootState}, {firstName, lastName, dob, email, password, passwordRepeat}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data: {token}} = await axios({
        url: `${rootState.apiAddress}/signup`,
        method: 'post',
        data: {
          firstName, lastName, dob, email, password, passwordRepeat
        }
      })
      commit('setToken', token, {root: true});

      commit('setLoading', false, {root: true});
      window.location.reload();
    } catch (err) {
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      Vue.toasted.global.showServerError({
        message
      }).goAway(4000)
    }
    commit('setLoading', false, {root: true});
  },
  PASSWORD_RESET: async ({rootState, commit}, {email}) => {
    commit('setLoading', true, {root: true});
    try {
      await axios({
        url: `${rootState.apiAddress}/forgot-password`,
        method: 'post',
        data: {email}
      });

      Vue.toasted.success('Request sent - If the specified email address exist in our records, you should receive a password reset email soon.', {className: 'app-font'}).goAway(4000);
    } catch (err) {
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      Vue.toasted.global.showServerError({message}).goAway(4000);
    }
    commit('setLoading', false, {root: true});
  },
  UPDATE_PASSWORD: async ({commit, rootState}, {newPassword, confirmPassword, code}) => {
    commit('setLoading', true, {root: true});
    try {
      await axios({
        url: `${rootState.apiAddress}${code ? `/change-password/` : '/api/my-account/change-password'}`,
        method: 'post',
        data: {
          code,
          password: newPassword,
          newPassword,
          confirmPassword
        },
        headers: {
          Authorization: `Bearer ${rootState.token}`
        }
      })

      Vue.toasted.success('Password update successful', {className: 'app-font'}).goAway(4000);
      commit('setLoading', false, {root: true});
      return true;
    } catch (err) {
      console.log(err)
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      Vue.toasted.global.showServerError({
        message
      }).goAway(4000)
    }
    commit('setLoading', false, {root: true});
  },
  GET_USER: async ({commit, rootState}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data: {me}} = await axios({
        url: `${rootState.apiAddress}/api/me`,
        headers: {
          Authorization: `Bearer ${rootState.token}`
        }
      })
      commit('setUser', me)
    } catch (err) {
      console.log(err)
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      console.log(message)
    }

    commit('setLoading', false, {root: true});
  },
  UPDATE_USER_INFO: async ({commit, rootState, dispatch}, {id, userInfo, skipReload = false}) => {
    commit('setLoading', true, {root: true});
    try {
      await axios({
        url: `${rootState.apiAddress}/api/users/${id}`,
        method: 'put',
        data: {
          userInfo: JSON.stringify(userInfo)
        },
        headers: {
          Authorization: `Bearer ${rootState.token}`
        }
      })
      if (!skipReload) {
        Vue.toasted.success('User details update successful', {className: 'app-font'}).goAway(4000);
        await dispatch('GET_USER')
      }
    } catch (err) {
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      Vue.toasted.global.showServerError({
        message
      }).goAway(4000)
    }
    commit('setLoading', false, {root: true});
  },
}
