export default {
  loading: state => state.loading,
  listings: state => state.listings,
  showSearchResults: state => state.showSearchResults,
  searchResults: state => state.searchResults,
  latestListings: state => state.latestListings,
  coolestPads: state => state.coolestPads,
  trendingProperties: state => state.trendingProperties,
  selectedListing: state => state.selectedListing,
  viewingTimes: state => state.viewingTimes
}
