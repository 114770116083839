import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Toasted from 'vue-toasted';
import router from './router';
import store from './store';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import './icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false

Vue.component('VueSlider', VueSlider)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Toasted)

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.toasted.register('showServerError', payload => {
  if (!payload.message) return 'Oh...something is wrong, please try again';
  return payload.message
}, {
  type: 'error',
  className: 'app-font'
})


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
