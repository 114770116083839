export default {
  setLoading: (state, loading) => state.loading = loading,
  setListings: (state, listings) => state.listings = listings,
  setSearchResults: (state, listings) => state.searchResults = listings,
  setLatestListings: (state, listings) => state.latestListings = listings,
  setCoolestPads: (state, listings) => state.coolestPads = listings,
  setTrendingProperties: (state, listings) => state.trendingProperties = listings,
  setShowSearchResults: (state, show) => state.showSearchResults = show,
  setSelectedListing: (state, listing) => state.selectedListing = listing,
  setViewingTimes: (state, viewingTimes) => state.viewingTimes =  viewingTimes
}
