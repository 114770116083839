import Vue from 'vue';
import Router from 'vue-router';
import Listings from './screens/listings';
import Community from './screens/community';
import SignIn from './screens/sign-in';
import SignUp from './screens/sign-up';
import ForgotPassword from './screens/forgot-password';
import PasswordReset from './screens/password-reset';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [{
    component: Listings,
    props: true,
    path: '/'
  }, {
    component: Community,
    props: true,
    path: '/community'
  }, {
    component: SignIn,
    props: true,
    path: '/sign-in'
  }, {
    component: SignUp,
    props: true,
    path: '/sign-up'
  }, {
    component: ForgotPassword,
    props: true,
    path: '/forgot-password'
  }, {
    component: PasswordReset,
    props: true,
    path: '/password-reset'
  }]
});

router.beforeEach((to, from, next) => {
  let outerRoutes = ['/sign-in', '/password-reset','/forgot-password', '/sign-up'];
  if (outerRoutes.includes(to.path) && localStorage.getItem('token')) return next('/');
  return next()
})

export default router;
