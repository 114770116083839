<template>
  <b-sidebar
    id="navigation-sidebar"
    right
    backdrop
    no-header
  >
    <b-row>
      <b-col>
        <b-list-group>
          <b-list-group-item>
            <a href="/">PLACES TO LIVE</a>
          </b-list-group-item>
          <b-list-group-item>
            <a href="https://dashboard.all-house.website">MANAGERS PORTAL</a>
          </b-list-group-item>
          <b-list-group-item>
            <a href="https://www.facebook.com/allhouse.nz" right>
              <b-icon-facebook
                font-scale="2"
              />
            </a>
          </b-list-group-item>
          <b-list-group-item>
            <a href="https://www.instagram.com/allhouse_nz" right>
              <b-icon-instagram
                font-scale="2"
              />
            </a>
          </b-list-group-item>
          <b-list-group-item v-if="user" @click="signout">
            <a >SIGN OUT</a>
          </b-list-group-item>
          <b-list-group-item v-else>
            <a href="/sign-in">SIGN IN</a>
          </b-list-group-item>
          <b-list-group-item v-b-toggle.navigation-sidebar>
            CLOSE
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-sidebar>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "navigation-sidebar",
    computed: {
      ...mapGetters('session', ['user'])
    },
    methods: {
      signout() {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
    }
  }
</script>

<style scoped>

</style>
