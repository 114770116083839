<template>
  <b-navbar type="dark" variant="dark" sticky class="top-navbar">
    <b-navbar-brand href="/" class="w-25">
      <img
        src="@/assets/allhouse-white.svg"
        style="width:162px"
        class="d-none d-md-block"
      />
      <img
        src="@/assets/allhouse_white_flag_transparent.png"
        style="height: 50px"
        class="d-sm-block d-md-none"
      />
    </b-navbar-brand>
    <div class="w-50 d-sm-block d-md-none">
    <b-button
      v-if="showSearchButton"
      class="text-uppercase" v-b-toggle.search-collapse
      variant="outline-light"
      pill
      block
    >
      Search
      <font-awesome-icon icon="sliders-h"/>
    </b-button>
    </div>
    <div class="d-none d-md-block w-25">
      <b-button
        class="text-uppercase" v-b-toggle.search-collapse
        variant="outline-light"
        pill
        block
        v-if="showSearchButton"
      >
        Search
        <font-awesome-icon icon="sliders-h"/>
      </b-button>
    </div>
    <div class="w-25 d-sm-block d-md-none">
      <b-button
        class="float-right"
        v-b-toggle.navigation-sidebar
        variant="outline-light"
        
      >
        <font-awesome-icon icon="bars"/>
      </b-button>
    </div>
    <b-navbar-nav
      align="end"
      class="w-50 text-uppercase d-none d-md-flex"
      style="font-size:12px;color:white !important"
    >
      <b-nav-item href="/" right>places to live</b-nav-item>
      <b-nav-item href="https://dashboard.all-house.website" right>Managers Portal</b-nav-item>
      <!--<b-nav-item href="#" right>manager portal</b-nav-item>-->
      <!--<b-nav-item href="https://www.facebook.com/allhouse.nz" right>-->
        <!--<b-icon-facebook-->
          <!--font-scale="2"-->
        <!--/>-->
      <!--</b-nav-item>-->
      <!--<b-nav-item href="https://www.instagram.com/allhouse_nz" right>-->
        <!--<b-icon-instagram-->
          <!--font-scale="2"-->
        <!--/>-->
      <!--</b-nav-item>-->
      <b-nav-item
        v-if="user && user._id"
        @click="signout"
        right>
        Sign Out
      </b-nav-item>
      <b-nav-item
        v-else
        href="/sign-in"
        right>
        Sign In
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
  //import { faFacebook } from '@fortawesome/free-brands-svg-icons'

  import {mapGetters} from "vuex";

  export default {
    name: "top-nav",
    components: {},
    computed: {
      ...mapGetters('session', ['user']),
      showSearchButton() {
        console.log(this.$route)
        return this.$route.path === '/'
      }
    },
    methods: {
      signout() {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
    }
  }
</script>

<style scoped>
  .top-navbar {
    background: #000 !important;
  }
</style>
