<template>
  <b-container>
    <b-row>
      <b-col sm="12" md="6" offset-md="3">
        <b-card
          class="mt-5"
          style="z-index: 2"
        >
          <b-card-title class="text-center">
            <img src="@/assets/allhouse.svg" style="height: 50px;">
          </b-card-title>
          <b-form @submit="submit">
            <b-form-group
            >
              <b-form-input
                required
                v-model="email"
                placeholder="Email"
                type="email"
              />
            </b-form-group>
            <b-form-group
            >
              <b-form-input
                required
                type="password"
                placeholder="Password"
                v-model="password"
              />
            </b-form-group>
            <b-form-group class="text-center">
              <img src="@/assets/loading_gif.gif" v-if="loading">
              <b-button
                block
                variant="dark"
                type="submit"
                v-if="!loading"
              >
                LOG IN
              </b-button>
              <b-button variant="outline-dark" block href="/sign-up">
                SIGN UP
              </b-button>
              <b-button variant="link" block href="/forgot-password">
                Forgot your password?
              </b-button>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <div class="background">
      <img src="@/assets/line-desktop.svg"/>
    </div>
  </b-container>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "sign-in",
    data: () => ({
      email: '',
      password: ''
    }),
    computed: {
      ...mapGetters('session', ['loading'])
    },
    methods: {
      async submit(e) {
        e.preventDefault()
        console.log(this.$router.currentRoute)
        let returnTo = '/';
        if (this.$router.currentRoute.query.returnTo) returnTo = this.$router.currentRoute.query.returnTo;
        await this.$store.dispatch('session/SIGN_IN', {email: this.email, password: this.password, returnTo});
      }
    }
  }
</script>

<style scoped>
  .background {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255,255,255,.5);
  }
  
  .background img {
    width: 100%;
    height: 200%;
    background-size: cover;
    transform: rotate(90deg);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  
  button[type="submit"] {
    background-color: #000320;
  }
</style>
