<template>
  <b-card
    no-body
    class="property-card"
    @click="setListing"
    v-b-modal.listing-modal
  >
    <b-card-body
      style="padding: 5px 0px"
    >
      <b-card-img
        :src="listing.images[0].url"
        style="height: 200px;max-height:200px"
        v-if="listing && listing.images && listing.images[0]"
      />
      <b-card-title>
            <span style="overflow: hidden;
    white-space: nowrap;;padding: 0px 5px">
              {{listing.location.address.suburb}}, {{listing.location.address.city}}
            </span>
        <br/>
        <small>{{listing.location.address.streetAddress}}</small>
      </b-card-title>
      <b-row>
        <b-col cols="3" class="pr-0">
          <h4>
            <img src="@/assets/icons/bed.svg" style="width:32px;margin-right: 5px"/>
            {{listing.description.bedrooms}}
          </h4>
        </b-col>
        <b-col cols="3" class="px-0">
          <h4>
            <img src="@/assets/icons/Icon 1.png" style="width:27px;margin-right: 5px"/>
            {{listing.description.bathrooms}}
          </h4>
        </b-col>
        <b-col cols="6" class="px-0">
          <h4>${{listing.description.rent}}p/w</h4>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
  export default {
    name: "listing-card",
    props: ['listing'],
    methods: {
      setListing() {
        this.$store.commit('listing/setSelectedListing', this.listing);
        this.$router.push({
          query: {
            id: this.listing._id
          }
        })
        this.$bvModal.show('listing-modal');
      }
    }
  }
</script>

<style scoped>

</style>
