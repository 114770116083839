import axios from 'axios';

export default {
  GET_LISTINGS: async ({commit, rootState}, {options, status = {$nin:['inactive', 'hold']}, filter}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data: {listings}} = await axios({
        url: `${rootState.apiAddress}/listings?options=${JSON.stringify(options)}&status=${JSON.stringify(status)}&filter=${filter ? JSON.stringify(filter) : ''}`
      })
      commit('setLoading', false, {root: true});
      return listings;
    } catch (err) {
      console.log(err)
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      console.log(message)
    }

    commit('setLoading', false, {root: true});
  },
  GET_SEARCH_RESULTS: async ({dispatch, commit}) => {
    const listings  = await dispatch('GET_LISTINGS', {
      options: {
        sort: "-advertising.listedAt",
        limit: 20
      }
    });
    commit('setLatestListings', listings)
  },
  GET_LASTEST_LISTINGS: async ({dispatch, commit}) => {
    const listings  = await dispatch('GET_LISTINGS', {
      options: {
        sort: "-advertising.listedAt",
        limit: 20
      }
    });
    commit('setLatestListings', listings)
  },
  GET_COOLEST_PADS: async ({dispatch, commit}) => {
    const listings  = await dispatch('GET_LISTINGS', {
      options: {
        sort: "-description.rent",
        limit: 20
      }
    });
    commit('setCoolestPads', listings)
  },
  GET_TRENDING_PROPERTIES: async ({dispatch, commit}) => {
    const listings  = await dispatch('GET_LISTINGS', {
      filter: {type: 'recently-booked'},
      options: {
        limit: 20
      }
    });
    commit('setTrendingProperties', listings)
  },
  GET_LISTING: async ({commit, rootState}, {id}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data: {listing}} = await axios({
        url: `${rootState.apiAddress}/listings/${id}`,
        headers: {
          Authorization: `Bearer ${rootState.token}`
        }
      });
      commit('setLoading', false, {root: true});
      return listing
    } catch (err) {
      console.log(err)
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      console.log(message)
    }

    commit('setLoading', false, {root: true});
  },
  GET_VIEWING_TIMES: async ({commit, rootState}, {listingId}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data: {result}} = await axios({
        url: `${rootState.apiAddress}/listings/${listingId}/viewing-times`
      })
      commit('setLoading', false, {root: true});
      return result;
    } catch (err) {
      console.log(err)
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      console.log(message)
    }

    commit('setLoading', false, {root: true});
  },
  BOOK_VIEWING: async ({commit, rootState}, {listingId, id, viewingInfo}) => {
    commit('setLoading', true, {root: true});
    try {
      const {data} = await axios({
        url: `${rootState.apiAddress}/api/matches`,
        method: 'POST',
        data: {
          ...viewingInfo,
          listingId,
          id
        },
        headers: {
          Authorization: `Bearer ${rootState.token}`
        }
      })
      commit('setLoading', false, {root: true});
      return data;
    } catch (err) {
      console.log(err)
      const message = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : rootState.defaultError
      console.log(message)
    }

    commit('setLoading', false, {root: true});
  }
}
