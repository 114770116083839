import Amplify, { Auth } from "aws-amplify"

Amplify.configure({
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_VOLSyt8Et",
    userPoolWebClientId: "5l130d83t2m44hhc07i9q36823",
    mandatorySignIn: false
  }
})

export default Auth
