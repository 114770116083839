<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <div
            class="images-reel"
            v-b-tooltip.hover
            title="Click to view gallery"
          >
            <div
              :style="`width: 200px;height: 200px;background-image:url('${image.urlThumbnailMedium || image.url}');`"
              class="image-tile"
              v-for="(image, imageIndex) of selectedListing.images"
              :key="image._id"
              @click="index = imageIndex"
            >
  
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <h5 class="text-center">
            {{selectedListing.location.address.address}}
          </h5>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <h4 class="text-center">
            <img src="@/assets/icons/bed.svg" style="width:32px;margin-right: 0px"/>
            {{selectedListing.description.bedrooms}}
          </h4>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <h4 class="text-center">
            <img src="@/assets/icons/Icon 1.png" style="width:32px;margin-right: 0px"/>
            {{selectedListing.description.bathrooms}}
          </h4>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <h4 class="text-center">
            <img src="@/assets/icons/car.svg" style="width:32px;margin-right: 0px"/>
            {{selectedListing.description.carparks}}
          </h4>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <h4 class="text-center">
            <img src="@/assets/icons/icon - calandar.png" style="width:32px;margin-right: 0px"/>
            {{moment(selectedListing.advertising.availableAt).format('DD MMM')}}
          </h4>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col>
          <strong>Description</strong>
          <p>{{selectedListing.advertising.description}}</p>
          <hr/>
          <div
            class="w-100"
            v-if="selectedListing.description.amenities"
          >
            <strong>Amenities</strong>
            <p>{{selectedListing.description.amenities}}</p>
            <hr/>
          </div>
          <div
            class="w-100"
            v-if="selectedListing.description.carParkDescription"
          >
            <strong>Car Parks Info</strong>
            <p>{{selectedListing.description.carParkDescription}}</p>
            <hr/>
          </div>
          <strong>Location</strong>
          <br/>
          <b-button
            target="_blank"
            :href="`https://www.google.com/maps/place/${googleAddress.replaceAll(' ','+')}/@${selectedListing.location.coordinates.latitude},${selectedListing.location.coordinates.longitude},20z`"
          >Check on Google Maps</b-button>
        </b-col>
      </b-row>
    </b-col>
    <cool-light-box
      :items="lightboxImages"
      :index="index"
      @close="index = null"
      loop
    />
  </b-row>
</template>

<script>
  import moment from 'moment';
  import CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  
  export default {
    name: "description-page",
    components: {
      CoolLightBox
    },
    props: ['selectedListing'],
    data: () => ({
      index: null
    }),
    computed: {
      lightboxImages() {
        return this.selectedListing.images.map(i => ({
          src: i.url
        }))
      },
      googleAddress() {
        return `${this.selectedListing.location.address.streetAddress}, ${this.selectedListing.location.address.suburb}`
      }
    },
    methods: {
      moment
    }
  }
</script>

<style scoped>
  .carousel-sld > img {
    width: auto !important;
  }
  
  .images-reel {
    width: 100%;
    overflow: scroll;
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  
  .image-tile {
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    min-width: 200px;
    margin-right: 10px;
    cursor: pointer;
  }
</style>
