import Vue from 'vue';
import Vuex from 'vuex'
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import listing from './modules/listing';
import session from './modules/session';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    listing,
    session
  },
  state: {
    apiAddress: process.env.VUE_APP_API_ADDRESS
      || 'http://localhost:8083'
      || 'https://api.allhouse.co.nz',
    token: localStorage.getItem('token'),
    loading: false
  },
  mutations,
  getters,
  actions
})
