<template>
  <b-container>
    <b-row>
      <b-col sm="12" md="6" offset-md="3">
        <b-card
          class="mt-5"
          style="z-index: 2"
        >
          <b-card-title class="text-center">
            <img src="@/assets/allhouse.svg" style="height: 50px;">
            <h5 class="text-center mt-2">Change Password</h5>
          </b-card-title>
          <b-form @submit="submit">
            <b-form-group
            >
              <b-form-input
                required
                type="password"
                placeholder="New Password"
                v-model="password"
              />
            </b-form-group>
            <b-form-group
            >
              <b-form-input
                required
                type="password"
                placeholder="Confirm Password"
                v-model="passwordRepeat"
              />
            </b-form-group>
            <b-form-group class="text-center">
              <img src="@/assets/loading_gif.gif" v-if="loading">
              <b-button
                block
                variant="dark"
                type="submit"
                v-if="!loading"
              >
                UPDATE PASSWORD
              </b-button>
              <b-button
                block
                variant="outline-dark" href="/sign-in">
                SIGN IN
              </b-button>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <div class="background">
      <img src="@/assets/line-desktop.svg"/>
    </div>
  </b-container>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "password-reset",
    data: () => ({
      password: '',
      passwordRepeat: ''
    }),
    computed: {
      ...mapGetters('session', ['loading'])
    },
    methods: {
      async submit(e) {
        e.preventDefault()
        let success = await this.$store.dispatch('session/UPDATE_PASSWORD', {
          newPassword: this.password,
          confirmPassword: this.passwordRepeat,
          code: this.$route.query.code
        });
        if (success) window.location.href = '/sign-in';
      }
    }
  }
</script>

<style scoped>
  .background {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255,255,255,.5);
  }
  
  .background img {
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
  }
  
  button[type="submit"] {
    background-color: #000320;
  }
</style>
