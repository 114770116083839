<template>
  <b-container>
  <b-row style="background: white">
    <img src="@/assets/hero.gif" class="hero-image"/>
    <b-col>
      <b-row align-content="center" align-h="center">
        <b-col style="text-align: center" sm="12" md="8" class="mt-3">
          
          <h3>Our Passion</h3>
          <p>
            By creating connections through adventure, events and community impact. We can live a more connected life full of wonder. #livebetter
          </p>
          <img src="@/assets/loading_gif.gif"/>
        </b-col>
      </b-row>
      <b-row align-content="center" align-h="center">
          <b-col style="text-align: center" sm="12" md="8" class="mt-3">
            <h3>Our People</h3>
          <p>
            In a world that is so digitally connected, we continue to live our lives behind screens. Our drive is to create a new possibility for those who want to live on the court and not in the stands.
          </p>
          
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <VueSlickCarousel v-bind="settings">
            <img src="@/assets/faces/1.jpg"/>
            <img src="@/assets/faces/2.jpg"/>
            <img src="@/assets/faces/3.jpg"/>
            <img src="@/assets/faces/4.jpg"/>
            <img src="@/assets/faces/5.jpg"/>
            <img src="@/assets/faces/6.jpg"/>
            <img src="@/assets/faces/7.jpg"/>
          </VueSlickCarousel>
        </b-col>
      </b-row>
      <b-row align-content="center">
        <b-col style="text-align: center">
          <h1 class="mt-5 mb-5">Crew Benefits</h1>
          <b-container>
            <b-row class="mb-5">
              <b-col>
                <div class="benefit">
                  <b-row>
                    <b-col>
                      Allhouse Access
                    </b-col>
                    <b-col>
                      <div class="img-container float-right">
                        <img src="@/assets/icons/key.svg" />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col class="pt-1">
                Finding your next flatmate has never been safer or easier.
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <div class="benefit">
                  <b-row>
                    <b-col>
                      Exclusive Deals
                    </b-col>
                    <b-col>
                      <div class="img-container float-right">
                        <img src="@/assets/icons/kingdom.svg" />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col class="pt-1">
                Registered Allhouse Crew get instant access to exclusive deals with suppliers and establishments.              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <div class="benefit">
                  <b-row>
                    <b-col>
                      Crew Merch
                    </b-col>
                    <b-col>
                      <div class="img-container float-right">
                        <img src="@/assets/icons/key.svg" />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col class="pt-1">
                Collaborated with local artists, our limited Crew merch is released to every new Crew member on sign up.
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <div class="benefit">
                  <b-row>
                    <b-col>
                      New Experiences
                    </b-col>
                    <b-col>
                      <div class="img-container float-right">
                        <img src="@/assets/icons/key.svg" />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col class="pt-1">
                Our focus is to bring activations, events and educational experiences to you every month.
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <img src="@/assets/partners-timeline.png" style="width: 100%"/>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  export default {
    name: "community",
    components: {
      VueSlickCarousel
    },
    data: () => ({
      settings: {
        "centerMode": true,
        "centerPadding": "20px",
        "focusOnSelect": true,
        "infinite": true,
        "slidesToShow": 3,
        "speed": 1000,
        "autoplay": true,
        autoplayspeed: 1000
      }
    })
  }
</script>

<style scoped>
.hero-image {
  width: 100%;
  height: auto;
  min-height: 200px;
}
  
  .benefit {
    background: #000;
    max-height: 80px;
    padding: 10px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    color:white;
    line-height: 50px;
  }
  
  .benefit .img-container {
    width: 50px;
    height: 50px;
    align-self: flex-end;
    text-align: right;
    background-color: white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
</style>
